<template>
  <section>
    <div class="subContainer">
      <vs-row vs-w="12" class="topo">
        <div class="titulo">
          <font-awesome-icon :icon="['fas', 'ban']" />
          <h2 class="tituloAtendimento">Desligamento</h2>
        </div>
      </vs-row>
      <div>
        <vs-row class="pt-d">
          <vs-col>
            <Message class="pd-t"
              :msg="idAttendance"
              :error="errorIdAttendance"
              v-if="idAttendance || errorIdAttendance"
            />
          </vs-col>
        </vs-row>

        <ValidationObserver ref="observer" v-slot="{ invalid }">
          <vs-row class="pd-t">
            <vs-col>
              <fieldset>
                <legend>Nome Colaborador <b style="color: red">*</b></legend>
                <ValidationProvider name="Nome Colaborador" rules="required">
                  <vs-select
                    autocomplete
                    @input-change="inputChange"
                    class="selectExample"
                    v-model="indexSelectedSub"
                    placeholder="Digite o Nome do Colaborador"
                  >
                    <vs-select-item
                      :key="index"
                      :value="index"
                      :text="item.NOME"
                      v-for="(item, index) in data.employees"
                    />
                  </vs-select>
                </ValidationProvider>
              </fieldset>
            </vs-col>
          </vs-row>

          <div v-if="indexSelectedSub != null">
            <vs-row class="pd-t">
              <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Função <b style="color: red">*</b></legend>
                  <vs-input
                    disabled
                    v-model="data.employees[indexSelectedSub].FUNCAO"
                    class="selectExampleDouble"
                  />
                </fieldset>
              </vs-col>

              <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Data de Admissão <b style="color: red">*</b></legend>
                  <vs-input
                    disabled
                    v-model="data.employees[indexSelectedSub].ADMISSAO"
                    class="selectExampleDouble"
                  />
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <fieldset>
                  <legend>Área / Setor <b style="color: red">*</b></legend>
                  <vs-input
                    disabled
                    v-model="data.employees[indexSelectedSub].SETOR"
                    class="selectExample"
                  />
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>
                    Email para Contato <b style="color: red">*</b>
                  </legend>
                  <vs-input
                    v-model="emailContact"
                    placeholder="Digite o Email para Contato"
                    class="selectExampleDouble"
                  />
                </fieldset>
              </vs-col>

              <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>
                    Telefone para Contato <b style="color: red">*</b>
                  </legend>
                  <vs-input
                    v-mask="'(##) #####-####'"
                    v-model="phoneContact"
                    placeholder="(00) 00000-0000"
                    class="selectExampleDouble"
                  />
                  <b style="font-size: 0.85em">Formato: (00) 00000-0000</b>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Data de Aviso <b style="color: red">*</b></legend>
                  <ValidationProvider name="Data de Aviso" rules="required">
                    <vs-input
                      v-model="noticeDate"
                      type="date"
                      class="selectExampleDouble"
                    />
                  </ValidationProvider>
                </fieldset>
              </vs-col>

              <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Data Desligamento <b style="color: red">*</b></legend>
                  <ValidationProvider name="Data Desligamento" rules="required">
                    <vs-input
                      v-model="shutdownDate"
                      type="date"
                      class="selectExampleDouble"
                    />
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Tipo Desligamento <b style="color: red">*</b></legend>
                  <ValidationProvider name="Tipo Desligamento" rules="required">
                    <vs-select
                      class="selectExampleDouble"
                      placeholder="Selecione o Tipo de Desligamento"
                      v-model="typeShutdown"
                    >
                      <vs-select-item
                        :key="index"
                        :value="item.value"
                        :text="item.text"
                        v-for="(item, index) in data.shutdown"
                      />
                    </vs-select>
                  </ValidationProvider>
                </fieldset>
              </vs-col>

              <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                <fieldset>
                  <legend>Gestor Imediato <b style="color: red">*</b></legend>
                  <ValidationProvider name="Gestor Imediato" rules="required">
                    <vs-input
                      v-model="immediateManager"
                      placeholder="Digite o Gestor Imediato"
                      class="selectExampleDouble"
                    />
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row
              v-if="
                typeShutdown == 'Pedido De Demissão' ||
                typeShutdown == 'Demissão sem justa causa'
              "
              class="pd-t"
            >
              <vs-col>
                <fieldset>
                  <legend>Aviso Prévio <b style="color: red">*</b></legend>
                  <ValidationProvider name="Aviso Prévio" rules="required">
                    <vs-select
                      class="selectExample"
                      placeholder="Selecione o Aviso Prévio"
                      v-model="earlyWarning"
                    >
                      <vs-select-item
                        :key="index"
                        :value="item.value"
                        :text="item.text"
                        v-for="(item, index) in data.noticeDate"
                      />
                    </vs-select>
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <fieldset>
                  <legend>
                    Colaborador responsável pelos e-mails do desligado
                    <b style="color: red">*</b>
                  </legend>
                  <ValidationProvider
                    name="Colaborador responsável pelos e-mails do desligado"
                    rules="required"
                  >
                    <vs-input
                      class="selectExample"
                      v-model="responsibleEmail"
                      placeholder="Digite o Colaborador responsável pelos e-mails do desligado"
                    />
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <fieldset>
                  <legend>
                    Colaborador responsável pelos atendimentos do desligado
                    <b style="color: red">*</b>
                  </legend>
                  <ValidationProvider
                    name="Colaborador responsável pelos atendimentos do desligado"
                    rules="required"
                  >
                    <vs-input
                      class="selectExample"
                      v-model="responsibleService"
                      placeholder="Digite o Colaborador responsável pelos atendimentos do desligado"
                    />
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <fieldset>
                  <legend>
                    Motivos da solicitação de desligamento
                    <b style="color: red">*</b>
                  </legend>
                  <ValidationProvider
                    name="Motivos da solicitação de desligamento"
                    rules="required"
                  >
                    <vs-select
                      class="selectExample"
                      placeholder="Selecione os Motivos da solicitação de desligamento"
                      v-model="selectedReasonTermination"
                    >
                      <vs-select-item
                        :key="index"
                        :value="item.value"
                        :text="item.text"
                        v-for="(item, index) in data.reasonTermination"
                      />
                    </vs-select>
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider name="Descreva o Motivo" rules="required">
                  <vs-textarea
                    label="Descreva o Motivo *"
                    height="210px"
                    rows="11"
                    class="textArea"
                    counter="500"
                    v-model="motive"
                  />
                </ValidationProvider>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider
                  name="Quais as ações tomadas para orientação do colaborador, visando o desenvolvimento, a retenção do mesmo e sua capacitação"
                  rules="required"
                >
                  <vs-textarea
                    label="Quais as ações tomadas para orientação do colaborador, visando o desenvolvimento, a retenção do mesmo e sua capacitação *"
                    height="210px"
                    rows="11"
                    class="textArea"
                    counter="500"
                    v-model="contributorGuidance"
                  />
                </ValidationProvider>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <fieldset>
                  <legend>
                    Você em algum momento deu feedback para o colaborador no
                    intuito de oportunizar o seu desenvolvimento e resgatar o
                    profissional <b style="color: red">*</b>
                  </legend>
                  <ValidationProvider
                    name="Você em algum momento deu feedback para o colaborador no intuito de oportunizar o seu desenvolvimento e resgatar o profissional"
                    rules="required"
                  >
                    <vs-select
                      class="selectExample"
                      placeholder="Selecione"
                      v-model="selectedMotive"
                    >
                      <vs-select-item
                        :key="index"
                        :value="item.value"
                        :text="item.text"
                        v-for="(item, index) in data.feedback"
                      />
                    </vs-select>
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row v-if="selectedMotive == 'Sim'" class="pd-t">
              <vs-col>
                <ValidationProvider
                  name="Descreva e informe qual a sua avaliação sobre esta situação, visto que não conseguiu evitar a demissão"
                  rules="required"
                >
                  <vs-textarea
                    label="Descreva e informe qual a sua avaliação sobre esta situação, visto que não conseguiu evitar a demissão"
                    height="210px"
                    rows="11"
                    class="textArea"
                    counter="500"
                    v-model="dismissalAssessment"
                  />
                </ValidationProvider>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <fieldset>
                  <legend>
                    Qual alternativa será adotada pelo gestor para reposição
                    e/ou substituição da vaga <b style="color: red">*</b>
                  </legend>
                  <ValidationProvider
                    name="Qual alternativa será adotada pelo gestor para reposição e/ou substituição da vaga"
                    rules="required"
                  >
                    <vs-select
                      class="selectExample"
                      placeholder="Selecione"
                      v-model="managerAlternativeSelected"
                    >
                      <vs-select-item
                        :key="index"
                        :value="item.value"
                        :text="item.text"
                        v-for="(item, index) in data.managerAlternative"
                      />
                    </vs-select>
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider name="Observação" rules="required|max: 500">
                  <vs-textarea
                    label="Observação"
                    height="210px"
                    rows="11"
                    class="textArea"
                    counter="500"
                    v-model="observer"
                  />
                </ValidationProvider>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col style="text-align: center">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>

                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>
            </vs-row>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </section>
</template>

<script>
import Person from "../../../../services/person";
import PessoalModel from "../../../../models/pessoal/pessoal.js";
import Attendance from "../../../../services/attendance";
import {
  convertDate,
  getLoginDataFromStorage,
} from "../../../../helpers/storage";

export default {
  name: "Desligamento",
  data: () => ({
    indexSelectedSub: null,
    emailContact: null,
    phoneContact: null,
    noticeDate: null,
    shutdownDate: null,
    typeShutdown: null,
    earlyWarning: null,
    immediateManager: null,
    responsibleEmail: null,
    responsibleService: null,
    selectedMotive: null,
    motive: null,
    contributorGuidance: null,
    feedbackSelected: null,
    dismissalAssessment: null,
    managerAlternativeSelected: null,
    idAttendance: null,
    errorIdAttendance: null,
    observer: null,
    selectedReasonTermination: null,
    data: {
      employees: null,
      errorEmployees: null,
      shutdown: [
        { text: "Pedido De Demissão", value: "Pedido De Demissão" },
        { text: "Término de contrato", value: "Término de contrato" },
        { text: "Demissão sem justa causa", value: "Demissão sem justa causa" },
      ],
      noticeDate: [
        { text: "Trabalhando", value: "Trabalhando" },
        { text: "Indenizado", value: "Indenizado" },
      ],
      reasonTermination: [
        { text: "Motivos comportamentais", value: "Motivos comportamentais" },
        { text: "Motivos técnicos", value: "Motivos técnicos" },
        { text: "Outros", value: "Outros" },
      ],
      feedback: [
        { text: "Sim", value: "Sim" },
        { text: "Não", value: "Não" },
      ],
      managerAlternative: [
        {
          text: "Não será reposta a vaga, redução de quadro",
          value: "Não será reposta a vaga, redução de quadro",
        },
        {
          text: "Será solicitado abertura de processo seletivo interno",
          value: "Será solicitado abertura de processo seletivo interno",
        },
        {
          text: "Será suprida por movimentação interna",
          value: "Será suprida por movimentação interna",
        },
        {
          text: "Será solicitado a abertura de processo seletivo externo",
          value: "Será solicitado a abertura de processo seletivo externo",
        },
      ],
    },
  }),

  mounted() {
    this.$vs.loading({ text: "Carregando..." });
    new Person()
      .getEmployees()
      .then(({ data }) => {
        this.data.employees = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.data.errorEmployees = response.data;
          console.log(response);
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },

  methods: {
    inputChange(event) {
      return event.target.value;
    },

    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocalId = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      let feedback =
        this.selectedMotive == "Não"
          ? "Não Houve Feedback"
          : this.dismissalAssessment;

      const payload1 = new PessoalModel().getDataByAttendanceId("P3-2");

      payload1.data.locationId = personLocalId.toString();
      payload1.data.personId = personId.toString();
      payload1.data.subject = "RH - MOVIMENTAÇÃO DE PESSOAL - DESLIGAMENTO INSTRUTOR / TUTOR / PROFESSOR";
      payload1.data.attendantId = attendantId.toString();
      payload1.data.requestText = `USUÁRIO: ${personName} - ${personId}\nMOTIVO: Desligamento do colaborador: ${this.data.employees[
        this.indexSelectedSub
      ].NOME.toString()} - ${this.data.employees[
        this.indexSelectedSub
      ].CHAPA.toString()}\nEMAIL: ${this.emailContact}\nTELEFONE: ${
        this.phoneContact
      }\nFUNÇÃO: ${
        this.data.employees[this.indexSelectedSub].FUNCAO
      }\nDATA DE ADMISSÃO: ${
        this.data.employees[this.indexSelectedSub].ADMISSAO
      }\nÁREA/SETOR: ${
        this.data.employees[this.indexSelectedSub].SETOR
      }\nDATA DE AVISO: ${convertDate(this.noticeDate)}\nTIPO DESLIGAMENTO: ${
        this.typeShutdown
      }\nAVISO PRÉVIO: ${this.earlyWarning}\nDATA DESLIGAMENTO: ${convertDate(
        this.shutdownDate
      )}\nGESTOR IMEDIATO: ${
        this.immediateManager
      }\nCOLABOADOR RESPONSÁVEL PELOS E-MAILS: ${
        this.responsibleEmail
      }\nCOLABORADOR RESPONSÁVEL PELOS ATENDIMENTOS: ${
        this.responsibleService
      }\nMOTIVOS DA SOLICITAÇÃO: ${
        this.selectedReasonTermination
      }\nDESCRIÇÃO DO MOTIVO: ${
        this.motive
      }\nAÇÃO TOMADAS PARA ORIENTAÇÃO DO COLABORADOR: ${
        this.contributorGuidance
      }\nVOCÊ DEU FEEDBACK PARA O COLABORADOR PARA SEU DESENVOLVIMENTO E RESGATAR O PROFISSIONAL: ${
        this.selectedMotive
      }\nDESCREVA QUAL A SUA AVALIAÇÃO SOBRE A SITUAÇÃO: ${feedback}\nALTERNATIVA ADOTADA PELO GESTOR PARA REPOSIÇÃO E/OU SUBSTITUIÇÃO DA VAGA: ${
        this.managerAlternativeSelected
      }\nOBSERVAÇÃO: ${this.observer}`;

      const payload2 = new PessoalModel().getDataByAttendanceId("P3-2");

      payload2.data.locationId = personLocalId.toString();
      payload2.data.personId = personId.toString();
      payload2.data.subject = "RH - PEDIDO DE DEMISSÃO";
      payload2.data.attendantId = attendantId.toString();
      payload2.data.requestText = `USUÁRIO: ${personName} - ${personId}\nMOTIVO: Desligamento do colaborador: ${this.data.employees[
        this.indexSelectedSub
      ].NOME.toString()} - ${this.data.employees[
        this.indexSelectedSub
      ].CHAPA.toString()}nEMAIL: ${this.emailContact}\nTELEFONE: ${
        this.phoneContact
      }\nFUNÇÃO: ${
        this.data.employees[this.indexSelectedSub].FUNCAO
      }\nDATA DE ADMISSÃO: ${
        this.data.employees[this.indexSelectedSub].ADMISSAO
      }\nÁREA/SETOR: ${
        this.data.employees[this.indexSelectedSub].SETOR
      }\nDATA DE AVISO: ${convertDate(this.noticeDate)}\nTIPO DESLIGAMENTO: ${
        this.typeShutdown
      }\nAVISO PRÉVIO: ${this.earlyWarning}\nDATA DESLIGAMENTO: ${convertDate(
        this.shutdownDate
      )}\nGESTOR IMEDIATO: ${
        this.immediateManager
      }\nCOLABOADOR RESPONSÁVEL PELOS E-MAILS: ${
        this.responsibleEmail
      }\nCOLABORADOR RESPONSÁVEL PELOS ATENDIMENTOS: ${
        this.responsibleService
      }\nMOTIVOS DA SOLICITAÇÃO: ${
        this.selectedReasonTermination
      }\nDESCRIÇÃO DO MOTIVO: ${
        this.motive
      }\nAÇÃO TOMADAS PARA ORIENTAÇÃO DO COLABORADOR: ${
        this.contributorGuidance
      }\nVOCÊ DEU FEEDBACK PARA O COLABORADOR PARA SEU DESENVOLVIMENTO E RESGATAR O PROFISSIONAL: ${
        this.selectedMotive
      }\nDESCREVA QUAL A SUA AVALIAÇÃO SOBRE A SITUAÇÃO: ${feedback}\nALTERNATIVA ADOTADA PELO GESTOR PARA REPOSIÇÃO E/OU SUBSTITUIÇÃO DA VAGA: ${
        this.managerAlternativeSelected
      }\nOBSERVAÇÃO: ${this.observer}`;

      const payload3 = new PessoalModel().getDataByAttendanceId("P3-2");

      payload3.data.locationId = personLocalId.toString();
      payload3.data.personId = personId.toString();
      payload3.data.subject = "RH - MOVIMENTAÇÃO DE PESSOAL - DESLIGAMENTO";
      payload3.data.attendantId = attendantId.toString();
      payload3.data.requestText = `USUÁRIO: ${personName} - ${personId}\nMOTIVO: Desligamento do colaborador: ${this.data.employees[
        this.indexSelectedSub
      ].NOME.toString()} - ${this.data.employees[
        this.indexSelectedSub
      ].CHAPA.toString()}nEMAIL: ${this.emailContact}\nTELEFONE: ${
        this.phoneContact
      }\nFUNÇÃO: ${
        this.data.employees[this.indexSelectedSub].FUNCAO
      }\nDATA DE ADMISSÃO: ${
        this.data.employees[this.indexSelectedSub].ADMISSAO
      }\nÁREA/SETOR: ${
        this.data.employees[this.indexSelectedSub].SETOR
      }\nDATA DE AVISO: ${convertDate(this.noticeDate)}\nTIPO DESLIGAMENTO: ${
        this.typeShutdown
      }\nAVISO PRÉVIO: ${this.earlyWarning}\nDATA DESLIGAMENTO: ${convertDate(
        this.shutdownDate
      )}\nGESTOR IMEDIATO: ${
        this.immediateManager
      }\nCOLABOADOR RESPONSÁVEL PELOS E-MAILS: ${
        this.responsibleEmail
      }\nCOLABORADOR RESPONSÁVEL PELOS ATENDIMENTOS: ${
        this.responsibleService
      }\nMOTIVOS DA SOLICITAÇÃO: ${
        this.selectedReasonTermination
      }\nDESCRIÇÃO DO MOTIVO: ${
        this.motive
      }\nAÇÃO TOMADAS PARA ORIENTAÇÃO DO COLABORADOR: ${
        this.contributorGuidance
      }\nVOCÊ DEU FEEDBACK PARA O COLABORADOR PARA SEU DESENVOLVIMENTO E RESGATAR O PROFISSIONAL: ${
        this.selectedMotive
      }\nDESCREVA QUAL A SUA AVALIAÇÃO SOBRE A SITUAÇÃO: ${feedback}\nALTERNATIVA ADOTADA PELO GESTOR PARA REPOSIÇÃO E/OU SUBSTITUIÇÃO DA VAGA: ${
        this.managerAlternativeSelected
      }\nOBSERVAÇÃO: ${this.observer}`;

      let vaga = this.data.employees[this.indexSelectedSub].FUNCAO.split(" ");
      let secao = this.data.employees[this.indexSelectedSub].SECAO.split(" ");
      let setor = this.data.employees[this.indexSelectedSub].SETOR.split(" ");
      let payloads =
        vaga[0] == "PROFESSOR(A)" ||
        vaga[0] == "PROF." ||
        vaga[0] == "INSTRUTOR" ||
        vaga[0] == "TUTOR" ||
        vaga[0] == "TUTOR(A)" ||
        (vaga[0] == "AUTONOMO" && setor[0] == "AUTONOMO" && secao[0] == "CURSO")
          ? payload1.data
          : this.typeShutdown == "Pedido De Demissão"
          ? payload2.data
          : payload3.data;

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payloads)
        .then(({ data }) => {
          this.idAttendance = data;
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response);
          }
        })
        .finally(() => {
          this.resetForm();
          this.$vs.loading.close();
        });
    },

    resetForm() {
      this.noticeDate = null;
      this.emailContact = null;
      this.phoneContact = null;
      this.typeShutdown = "";
      this.earlyWarning = "";
      this.shutdownDate = null;
      this.immediateManager = null;
      this.responsibleEmail = null;
      this.responsibleService = null;
      this.selectedMotive = "";
      this.motive = null;
      this.contributorGuidance = null;
      this.dismissalAssessment = null;
      this.managerAlternativeSelected = "";
      this.observer = null;
      this.selectedReasonTermination = "";
      this.indexSelectedSub = null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>
