<template>
  <div class="subContainer">
    <vs-row vs-w="12" class="topo">
      <div class="titulo">
        <font-awesome-icon :icon="['fas', 'user-plus']" />
        <h2 class="tituloAtendimento">Aumento de Quadro Docente</h2>
      </div>
    </vs-row>

    <div>
      <vs-row class="pt-d">
        <vs-col>
          <Message class="pd-t"
            :msg="idAttendance"
            :error="errorIdAttendance"
            v-if="idAttendance || errorIdAttendance"
          />
        </vs-col>
      </vs-row>

      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <vs-row class="pd-t">
          <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Nome Indicação <b style="color: red">*</b></legend>
              <ValidationProvider name="Nome Indicação">
                <vs-input
                  v-model="nameIndication"
                  placeholder="Digite o Nome da Indicação"
                  class="selectExampleDouble"
                />
              </ValidationProvider>
            </fieldset>
          </vs-col>

          <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Telefone Indicação <b style="color: red">*</b></legend>
              <ValidationProvider
                name="Telefone Indicação"
                rules="required|min:15"
              >
                <vs-input
                  v-model="phoneIndication"
                  placeholder="(00) 00000-0000"
                  v-mask="'(##) #####-####'"
                  class="selectExampleDouble"
                />
              </ValidationProvider>
              <b style="font-size: 0.85em">Formato: (00) 00000-0000</b>
            </fieldset>
          </vs-col>
        </vs-row>

        <vs-row class="pd-t">
          <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Data de Início <b style="color: red">*</b></legend>
              <ValidationProvider name="Data de Início" rules="required">
                <vs-input
                  type="date"
                  v-model="startDate"
                  class="selectExampleDouble"
                />
              </ValidationProvider>
            </fieldset>
          </vs-col>

          <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>
                Prazo Limite para Contratação <b style="color: red">*</b>
              </legend>
              <ValidationProvider
                name="Prazo Limite para Contratação"
                rules="required"
              >
                <vs-input
                  type="date"
                  v-model="limitDate"
                  class="selectExampleDouble"
                />
              </ValidationProvider>
            </fieldset>
          </vs-col>
        </vs-row>

        <vs-row class="pd-t">
          <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Tipo de Docente <b style="color: red">*</b></legend>
              <ValidationProvider name="Tipo Contratação" rules="required">
                <vs-select
                  class="selectExampleDouble"
                  placeholder="Selecione o Tipo de Docente"
                  v-model="indexSelectedTeacher"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item, index) in data.typeTeacher"
                  />
                </vs-select>
              </ValidationProvider>
            </fieldset>
          </vs-col>

          <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Curso <b style="color: red">*</b></legend>
              <ValidationProvider name="Curso" rules="required">
                <vs-select
                  class="selectExample"
                  v-model="nameCourse"
                  placeholder="Selecione um Curso"
                  v-on:change="searchDiscipline(nameCourse.CODCURSO)"
                >
                  <vs-select-item
                    :key="item.CODCURSO"
                    :value="item"
                    :text="item.NOME"
                    v-for="item in courses"
                  />
                </vs-select>
              </ValidationProvider>
            </fieldset>
          </vs-col>
        </vs-row>

        <vs-row 
          class="pd-t" 
          v-if="nameCourse"
        >
          <vs-col vs-lg="12" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Primeira Disciplina <b style="color: red">*</b></legend>
              <ValidationProvider name="Primeira Disciplina" rules="required">
                <vs-select
                  class="selectExample"
                  v-model="nameDiscipline"
                  placeholder="Selecione uma Disciplina"
                >
                  <vs-select-item
                    :key="item.DISCIPLINA"
                    :value="item.DISCIPLINA"
                    :text="item.DISCIPLINA"
                    v-for="item in disciplines"
                  />
                </vs-select>
              </ValidationProvider>
            </fieldset>
          </vs-col>
        </vs-row>

        <vs-row class="pd-t">
          <vs-col vs-lg="12" vs-sm="12" vs-xs="12">
            <fieldset>
              <legend>Filial Destino <b style="color: red">*</b></legend>
              <ValidationProvider name="Filial Destino" rules="required">
                <span v-for="(unit, index) in units" :key="index">
                  <input
                    type="checkbox"
                    :id="index"
                    class="checkboxTurn"
                    v-model="unitsSelected"
                    :value="unit.CODFILIAL"
                  />{{ unit.CODFILIAL }}
                </span>
              </ValidationProvider>
            </fieldset>
          </vs-col>
        </vs-row>
        <vs-row class="pd-t">
          <vs-col>
            <fieldset>
              <legend>Turno <b style="color: red">*</b></legend>
              <ValidationProvider name="Turno" rules="required">
                <div class="boxTurn">
                  <span v-for="(shifts, index) in selectedShift" :key="index">
                    <input
                      type="checkbox"
                      :id="index"
                      class="checkboxTurn"
                      v-model="shift"
                      :value="shifts.value"
                    />{{ shifts.value }}
                  </span>
                </div>
              </ValidationProvider>
            </fieldset>
          </vs-col>
        </vs-row>

        <vs-row class="pd-t">
          <vs-col>
            <fieldset>
              <legend>Horário <b style="color: red">*</b></legend>
              <ValidationProvider name="Horário" rules="required">
                <div class="horario">
                  <vs-input
                    class="input-horario"
                    v-model="time[0]"
                    v-mask="'##:##'"
                  />
                  <span>às</span>
                  <vs-input
                    class="input-horario"
                    v-model="time[1]"
                    v-mask="'##:##'"
                  />
                  <span>e</span>
                  <vs-input
                    class="input-horario"
                    v-model="time[2]"
                    v-mask="'##:##'"
                  />
                  <span>às</span>
                  <vs-input
                    class="input-horario"
                    v-model="time[3]"
                    v-mask="'##:##'"
                  />
                </div>
              </ValidationProvider>
            </fieldset>
          </vs-col>
        </vs-row>

        <vs-row class="pd-t">
          <vs-col>
            <fieldset>
              <legend>Dias <b style="color: red">*</b></legend>
              <ValidationProvider name="Dias" rules="required">
                <div class="boxTurn">
                  <span v-for="(weeks, index) in selectedWeek" :key="index">
                    <input
                      type="checkbox"
                      :id="index"
                      class="checkboxTurn"
                      v-model="week.weekArray"
                      :value="weeks.value"
                    />{{ weeks.value }}
                  </span>
                </div>
              </ValidationProvider>
            </fieldset>
          </vs-col>
        </vs-row>

        <vs-row class="pd-t">
          <vs-col>
            <ValidationProvider name="Descrição Vaga" rules="required|max: 500">
              <vs-textarea
                label="Descrição da Vaga *"
                height="210px"
                rows="11"
                class="textArea"
                counter="500"
                v-model="solicitation"
              />
            </ValidationProvider>
          </vs-col>
        </vs-row>

        <vs-row class="pd-t">
          <vs-col style="text-align: center">
            <vs-button
              color="danger"
              type="gradient"
              class="button-protocol"
              @click="resetForm()"
            >
              <font-awesome-icon :icon="['fas', 'times-circle']" />
              Cancelar
            </vs-button>

            <vs-button
              class="button-protocol"
              color="success"
              type="gradient"
              @click="createAttendance()"
              :disabled="invalid"
            >
              <font-awesome-icon :icon="['fas', 'check']" />
              Gerar
            </vs-button>
          </vs-col>
        </vs-row>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import Units from "../../../../services/units";
import Management from "../../../../services/management";
import Attendance from "../../../../services/attendance";
import PessoalModel from "../../../../models/pessoal/pessoal.js";
import {
  getLoginDataFromStorage,
  convertDate,
} from "../../../../helpers/storage";

export default {
  name: "AumentoQuadroDocente",
  data: () => ({
    typeSelected: null,
    indexSelectedSub: null,
    data: {
      null: null,
      typeTeacher: [
        {
          text: "Professor(a) Nível Técnico",
          value: "Professor(a) Nível Técnico",
        },
        {
          text: "Professor(a) Nível Superior",
          value: "Professor(a) Nível Superior",
        },
        {
          text: "Professor(a) Nível Pós-Graduação",
          value: "Professor(a) Nível Pós-Graduação",
        },
        {
          text: "Instrutor(a) Cursos de Livres",
          value: "Instrutor(a) Cursos de Idiomas",
        },
        { text: "Responsável Técnico(a)", value: "Responsável Técnico(a)" },
        { text: "Supervisor(a) de Estágio", value: "Supervisor(a) de Estágio" },
        { text: "Tutor(a)", value: "Tutor(a)" },
      ],
    },
    selectedWeek: [
      { text: "Segunda", value: "Segunda" },
      { text: "Terça", value: "Terça" },
      { text: "Quarta", value: "Quarta" },
      { text: "Quinta", value: "Quinta" },
      { text: "Sexta", value: "Sexta" },
      { text: "Sábado", value: "Sábado" },
    ],
    week: {
      weekArray: [],
    },
    selectedShift: [
      { text: "Manhã", value: "Manhã" },
      { text: "Tarde", value: "Tarde" },
      { text: "Noite", value: "Noite" },
    ],
    units: [],
    unitsSelected: [],
    nameCourse: null,
    nameDiscipline: null,
    courses: [],
    disciplines: [],
    errorCourses: null,
    shift: [],
    indexSelectedTeacher: null,
    indexSelectedWeekend: null,
    nameIndication: "",
    phoneIndication: "",
    startDate: null,
    limitDate: null,
    time: [],
    solicitation: null,
    idAttendance: null,
    errorIdAttendance: null,
  }),

  mounted() {
    new Units()
      .getUnits()
      .then(({ data }) => {
        this.units = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data.error;
        }
      });
    this.$vs.loading({ text: "Carregando..." });
    new Units()
      .getAllCourses()
      .then(({ data }) => {
        this.courses = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.errorCourses = response.data.error;
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },

  methods: {
    searchDiscipline(course) {
      this.$vs.loading({ text: "Carregando..." });
      new Management()
        .getDisciplineByCourseCode(course)
        .then(({ data }) => {
          this.disciplines = Array.isArray(data) ? data : [data];
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorCourses = response.data.error;
          }
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },

    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocalId = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new PessoalModel().getDataByAttendanceId("P1");

      payload.data.locationId = personLocalId.toString();
      payload.data.deadline = this.limitDate;
      payload.data.personId = personId.toString();
      payload.data.attendantId = attendantId.toString();
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nMOTIVO: Aumento de Quadro - Docente \nINDICAÇÃO: (Nome: ${this.nameIndication.toUpperCase()} || Contato: ${
        this.phoneIndication
      })\nDATA DE INICIO: ${convertDate(
        this.startDate
      )}\nPRAZO LIMITE PARA CONTRATAÇÃO: ${convertDate(
        this.limitDate
      )}\nTIPO DE DOCENTE: ${this.indexSelectedTeacher}\nFILIAL DESTINO: ${
        this.unitsSelected
      }\nCURSO: ${this.nameCourse.NOME}\nPRIMEIRA DISCIPLINA: ${this.nameDiscipline}\nTURNO: ${this.shift}\nHORÁRIO: ${
        this.time[0]
      } ATÉ ${this.time[1]} E DAS ${this.time[2]} ATÉ ${this.time[3]}\nDIAS: ${
        this.week.weekArray
      }\nDESCRIÇÃO DA VAGA: ${this.solicitation.toUpperCase()}`;

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data.error;
          }
        })
        .finally(() => {
          this.$vs.loading.close();
          this.resetForm();
        });
    },

    resetForm() {
      this.indexSelectedSalary = "";
      this.nameIndication = "";
      this.phoneIndication = null;
      this.nameCourse = null;
      this.nameDiscipline = null;
      this.startDate = null;
      this.time = [];
      this.selectedUnit = "";
      this.unitsSelected = [];
      this.shift = [];
      this.solicitation = null;
      this.week.weekArray = [];
      this.indexSelectedWeekend = null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    inputChange(event) {
      return event.target.value;
    },
  },
};
</script>

<style scoped>
.checkboxUnit {
  display: inline-grid;
  padding: 3px 5px 1px 1px;
}
.input-horario {
  width: 5.6em;
}

@media screen and (max-width: 650px) {
  .input-horario {
    width: 5em;
  }
}

@media (max-width: 580px) {
  .input-horario {
    width: 5em;
  }
}

@media (max-width: 530px) {
  .checkboxTurn {
    width: 2.6em;
  }

  .boxTurn {
    flex-direction: column;
  }

  .horario {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 440px) {
  .horario > span {
    margin-left: 0.5em;
  }
}

@media (max-width: 370px) {
  .checkboxTurn {
    font-size: 0.9em;
  }
}
</style>
